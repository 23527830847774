import React from 'react';
import './style.css';

const EventSection = ({ title, desc }) => {
    return (
        <div className="EventSection">
            <h3 className="EventSection-title">{title}</h3>
            <p className="EventSection-desc">{desc}</p>
        </div>
    );
};

export default EventSection;