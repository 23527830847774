import { useRef, forwardRef } from 'react';
import { Parallax } from 'react-parallax';
import Countdown from './components/Countdown';
import EventSection from './components/EventSection';
import SectionTitle from './components/SectionTitle';
import Map from './components/Map';
import NameLogo from './components/NameLogo';

import BabyRoom from './images/baby-room.jpg';
import { ReactComponent as GiftPin } from './images/gifts-pin.svg';
import { ReactComponent as GamesPin } from './images/games-pin.svg';
import { ReactComponent as FoodPin } from './images/food-pin.svg';
import { ReactComponent as Triangule } from './images/triangule.svg';
import { ReactComponent as LiverpoolLogo } from './images/liverpool-logo.svg';

import MesaQR from './images/mesa_qr.png';

import './App.css';

const items = [
  { key: "FoodPin", Icon: FoodPin, title: 'Deliciosa Comida', desc: 'Acompáñanos a compartir la mesa con una rica comida especial, además de un rico pastel en honor de Nicole' },
  { key: "GamesPin", Icon: GamesPin, title: 'Mucha Diversion', desc: 'Te invitaremos a participar a diversos juegos con los cuales disfrutaremos de un divertido momento lleno de sorpresas' },
  { key: "GiftPin", Icon: GiftPin, title: 'Sorpresas', desc: 'El ganador se llevará un premio muy especial!.' },
];

const ParallaxContainer = ({children}) => (
  <Parallax blur={1} className="Section mesa-section-fixed" bgImage={BabyRoom} bgImageAlt="the baby" strength={300}>
      {children}
  </Parallax>
);

const Item = ({ Icon, title,  desc }) => {
  return (
    <li className="Item">
      <div className="Item-image">
        <Icon />
      </div>
      <div className="Item-content">
        <h3>{title}</h3>
        <p>{desc}</p>
      </div>
    </li>
  );
};

const ItemList = ({ items }) => (
  <ul className='ItemList'>
    {items.map(item => (<Item {...item} />))}
  </ul>
);

const Section = forwardRef(({ children, className }, ref) => (
  <section className={`Section ${className}`} ref={ref}>
    {children}
  </section>
));


const SectionWrapper = ({ children, className = '' }) => (
  <div className={`Section-wrapper ${className}`}>
    {children}
  </div>
);

const location = {
  address: 'Lázaro Cárdenas #9, Sipeh Animas, Xalapa, Ver.',
  lat: 19.5250,
  lng: -96.8965,
}

function App() {
  const inicioRef = useRef();
  const eventRef = useRef();
  const itemsRef = useRef();
  const mesaRef = useRef();
  const thanksRef = useRef();
  const locationRef = useRef();
  // let event = new Date(2021, 12, 18);
  let event = new Date('December 18, 2021 15:00:00')
  const backToElement = (ref) => {
    ref.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <div className="App" ref={inicioRef}>
      <section className="content-page">
        <Section className="welcome-section">
          <SectionWrapper className="welcome-section-wrapper">
            <div className="welcome-section-logo">
              <NameLogo />
            </div>
            <div className="welcome-section-desc">
              <span className="welcome-section-desc-title">Acompañanos a darle la bienvenida!</span>
              <p className="welcome-section-desc-subtitle">Comienza el evento en:</p>
              <Countdown date={event.getTime()} />
            </div>
          </SectionWrapper>
        </Section>
        <Section className="items-section" ref={itemsRef}>
          <SectionWrapper>
            <ItemList items={items} />
          </SectionWrapper>
        </Section>
        <ParallaxContainer>
          <Section className="mesa-section" ref={mesaRef}>
              <SectionWrapper>
                <div className="Mesa">
                  <h3 className="Mesa-title">Mesa de Regalos</h3>
                  <div className="Mesa-content">
                    <p>Mira nuestra mesa de regalos, podras encontrar diferentes articulos para Nicole</p>
                    <img src={MesaQR} style={{ margin: 24, width: 200 }}/>
                    <p className="Mesa-subtitle">Numero de Evento</p>
                    <p className="Mesa-number">50709404</p>
                    <div className="Mesa-liverpool">
                      <LiverpoolLogo />
                    </div>
                  </div>
                </div>
              </SectionWrapper>
          </Section>
        </ParallaxContainer>
        <Section className="event-section" ref={eventRef}>
          <SectionWrapper>
            <SectionTitle>Evento</SectionTitle>
            <div className="EventContent">
              <EventSection title="Lugar" desc="Hotel HB" />
              <EventSection title="Hora" desc="15:00 hrs" />
              <EventSection title="Dirección" desc="Av. Lázaro Cárdenas  CP:91010 Xalapa-Enríquez, Ver." />
              <EventSection title="Fecha" desc="18 de Diciembre de 2021" />
            </div>
          </SectionWrapper>
        </Section>
        <Section className="thanks-section" ref={thanksRef}>
          <SectionWrapper>
            <h2>Gracias</h2>
            <p>Siento mucho no estar con ustedes, pero estoy algo ocupada por ahora pintando mis cachetitos y preparandome para mi llegada para que mis padres esten muy orgullosos.</p>
          </SectionWrapper>
          <Triangule style={{ position: 'absolute', width: "100%", bottom: 0 }} />
        </Section>
        <Section className="map-section" ref={locationRef}>
          <SectionWrapper>
            <SectionTitle>Ubicación</SectionTitle>
            <Map location={location} zoomLevel={17} />
          </SectionWrapper>
        </Section>
      </section>
      <footer className="MainFooter">
        <div className="MainFooter-wrapper">
          <div className="MainFooter-content">
            <ul className="MainFooter-menu">
              <li><button onClick={() => backToElement(inicioRef)}>Inicio</button></li>
              <li><button onClick={() => backToElement(itemsRef)}>Información del evento</button></li>
              <li><button onClick={() => backToElement(mesaRef)}>Mesa de regalos</button></li>
              <li><button onClick={() => backToElement(eventRef)}>Evento</button></li>
              <li><button onClick={() => backToElement(thanksRef)}>Gracias</button></li>
              <li><button onClick={() => backToElement(locationRef)}>Ubicación</button></li>
            </ul>
            <div className="MainFooter-back">
              <button onClick={() => backToElement(inicioRef)}>Volver al inicio</button>
            </div>
          </div>
          <div className="MainFooter-copy">
            <p>&copy; 2021 Copyright, Todos los derechos Reservados</p>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
