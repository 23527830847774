import './style.css';

const CounterStat = ({ time, label, className='' }) => {
    return (
        <div className={`CounterStat ${className}`}>
            <div className={`CounterStat-time`}>
                <span className="number">{time}</span>
            </div>
            <span className="label">{label}</span>
        </div>
    );
};

export default CounterStat;