import React from 'react';
import { ReactComponent as Unicorn } from '../../images/unicorn-colors.svg';
import './style.css';

const NameLogo = () => {
    return (
        <div className="NameLogo">
          <Unicorn className="NameLogo-unicorn" />
          <span className="NameLogo-shower">Baby Shower</span>
          <h1 className="NameLogo-title">Nicole</h1>
        </div>
    );
};

export default NameLogo;