import React, { useEffect, useState } from "react";
import CounterStat from '../CounterStat';
import DayStat from '../DayStat';
import './style.css';

const calculateTimeLeft = (date) => {
  let difference = date - (new Date());
  let timeLeft;
  const segs = Math.floor((difference / 1000) % 60);
  const mins = Math.floor((difference / 1000 / 60) % 60);
  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: mins < 10 ? `0${mins}` : mins,
      seconds: segs < 10 ? `0${segs}` : segs,
    };
  }

  return timeLeft;

}

const Countdown = ({ date }) => {
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(date));

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft(date));
    }, 1000);
    return () => clearTimeout(timer);
  });

  if (!timeLeft) return <span>El Evento ya Comenzo!</span>;
  const labelToSpanish = {
    days: 'Dias',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
  };

  const { days, ...counter } = timeLeft;

  return (
    <div className="Countdown Countdown--pink">
      <div className="Countdown-days">
        <DayStat key={'days'} className="daysClass`" time={days} label={labelToSpanish['days']} />
      </div>
      <div className="Countdown-counter">
        {Object.keys(counter).map(element => {
          return (
            <CounterStat key={element} className={`${element}Class`} time={timeLeft[element]} label={labelToSpanish[element]} />
          );
        })}
      </div>
     
    </div>  
  );
};

export default Countdown;