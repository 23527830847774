import './style.css';

const DayStat = ({ time, label, className='' }) => {
    return (
        <div className={`DayStat ${className}`}>
            <span className="label">{label}</span>
            <span className="number">{time}</span>
        </div>
    );
};

export default DayStat;